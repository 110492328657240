import React from 'react'

import { Checkbox } from './style'

type AcceptTermsProps = {
  accept: string | boolean;
  setAccept: (value: boolean) => boolean | void;
  name: string;
  label: string;
  styles: string;
}

function NewsLetters (props: AcceptTermsProps) {
  function handleCheck (event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    props.setAccept(inputValue)
  }

  return (
    <Checkbox>
      <input
        name={props.name}
        value={props.accept.toString()}
        id={props.name}
        type='checkbox'
        className='form-input-check'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheck(event)}
      />
      <label className={`form-label-check ${props.styles}`} htmlFor={props.name}>
        { props.label}
      </label>
    </Checkbox>
  )
}

NewsLetters.defaultProps = {
  name: 'receber-boletim-noticias',
}

export default NewsLetters
